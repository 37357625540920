<template>
  <div id="home">
    <div class="titleLine">
        ssssssssss
    </div>
  </div>
</template>

<script>
export default {
  name:'Home',
  components: {

  },
  data() {
    return {
      userInfo:{
        
      }
    }
  },
  methods:{},
  created(){

  },
}
</script>

<style scoped>
#home{
  width: 100%;
  height: 100%;
  background: brown;
}
</style>
